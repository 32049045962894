import React, { useState } from "react";
import { motion, useMotionValue, useSpring, useTransform } from "framer-motion";
import {
  Github,
  Linkedin,
  Mail,
  MapPin,
  Download,
  ExternalLink,
  Globe,
} from "lucide-react";
import content from "../content.json";
import TiltCard from "./TiltCard";
import About from "./About";
import Cv from "./Cv";
import Projects from "./Projects";

const Portfolio = () => {
  const [activeSection, setActiveSection] = useState("about");

  return (
    <div className="min-h-screen bg-gray-950 text-white overflow-hidden relative">
      {/* Pulsating background */}
      <div className="absolute inset-0 overflow-hidden">
        <div
          className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-800 to-gray-950"
          style={{
            animation: "pulse 8s cubic-bezier(0.4, 0, 0.6, 1) infinte",
          }}
        />
      </div>

      {/* Grain texture */}
      <div
        className="absolute inset-0 opacity-30"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 256 256' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
          backgroundRepeat: "repeat",
          mixBlendMode: "overlay",
        }}
      />

      {/* Main content */}
      <div className="relative z-10 container mx-auto px-4 py-16">
        <nav className="mb-12">
          <ul className="flex justify-center space-x-6">
            {["about", "cv", "projects"].map((section) => (
              <li key={section}>
                <button
                  onClick={() => setActiveSection(section)}
                  className={`text-lg font-semibold px-4 py-2 rounded-full transition-colors ${
                    activeSection === section
                      ? "bg-blue-600 text-white"
                      : "text-gray-400 hover:text-white"
                  }`}
                >
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </button>
              </li>
            ))}
          </ul>
        </nav>

        <TiltCard>
          <motion.div
            key={activeSection}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="glassmorphism p-8 rounded-2xl backdrop-blur-lg bg-white bg-opacity-5"
            style={{
              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
              border: "1px solid rgba(255, 255, 255, 0.18)",
            }}
          >
            {activeSection === "about" && <About />}

            {activeSection === "cv" && <Cv />}

            {activeSection === "projects" && <Projects />}
          </motion.div>
        </TiltCard>
      </div>
    </div>
  );
};

export default Portfolio;
