import React, { useState } from "react";
import content from "../content.json";
import {
  Clock,
  GitBranch,
  Github,
  Globe,
  Linkedin,
  Mail,
  MapPin,
} from "lucide-react";
import TiltCard from "./TiltCard";
import { motion, AnimatePresence } from "framer-motion";

const About = () => {
  const [showTimeline, setShowTimeline] = useState(false);

  return (
    <div className="text-white p-8">
      <div className="max-w-4xl mx-auto">
        <TiltCard className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
          <div className="md:flex">
            <div className="md:flex-shrink-0">
              <TiltCard className="!h-48 !w-48 rounded-full overflow-hidden bg-gray-700 mx-auto md:m-6">
                <img
                  className="h-full w-full object-cover"
                  src="/assets/pfp.png"
                  alt="Pelle Martinius Nilsen"
                />
              </TiltCard>
            </div>
            <div className="p-8">
              <h1 className="text-3xl font-bold mb-2">
                {content.personalInfo.name}
              </h1>
              <h2 className="text-xl text-blue-400 mb-4">
                {content.personalInfo.title}
              </h2>
              <p className="text-gray-300 mb-6">
                {content.personalInfo.summary}
              </p>
              <div className="flex items-center mb-4">
                <MapPin className="mr-2 text-gray-400" size={18} />
                <span className="text-gray-300">
                  {content.personalInfo.location}
                </span>
              </div>
              <div className="flex items-center mb-6">
                <Mail className="mr-2 text-gray-400" size={18} />
                <a
                  href={`mailto:${content.personalInfo.email}`}
                  className="text-blue-400 hover:text-blue-300 transition-colors"
                >
                  {content.personalInfo.email}
                </a>
              </div>
              <div className="flex space-x-4">
                <a
                  href={content.socialLinks.github}
                  target="_blank"
                  className="text-gray-400 hover:text-white transition-colors"
                  rel="noopener noreferrer"
                >
                  <Github size={24} />
                </a>
                <a
                  href={content.socialLinks.linkedin}
                  target="_blank"
                  className="text-gray-400 hover:text-white transition-colors"
                  rel="noopener noreferrer"
                >
                  <Linkedin size={24} />
                </a>
                <a
                  href={content.socialLinks.website}
                  target="_blank"
                  className="text-gray-400 hover:text-white transition-colors"
                  rel="noopener noreferrer"
                >
                  <Globe size={24} />
                </a>
              </div>
            </div>
          </div>
        </TiltCard>

        <TiltCard className="mt-8 bg-gray-800 rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-bold mb-4">About Me</h2>
          <p className="text-gray-300 mb-4">
            As a passionate full-stack developer, I thrive on creating elegant
            solutions to complex problems. My experience spans both front-end
            and back-end development, allowing me to build comprehensive web
            applications from the ground up.
          </p>
          <p className="text-gray-300 mb-4">
            I'm constantly expanding my skill set and staying up-to-date with
            the latest technologies in the ever-evolving world of web
            development. My goal is to create efficient, scalable and
            user-friendly applications that make a positive impact.
          </p>
          <p className="text-gray-300 mb-4">
            When I'm not coding, you can find me exploring new technologies,
            contributing to open-source projects or piloting drones to capture
            breathtaking aerial footage.
          </p>
        </TiltCard>

        <div className="mt-8 text-center">
          <TiltCard>
            <button
              onClick={() => setShowTimeline(!showTimeline)}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-300"
            >
              <Clock className="mr-2" />
              <span>
                {showTimeline ? "Hide Timeline" : "Start Interactive Timeline"}
              </span>
            </button>
          </TiltCard>
        </div>

        <AnimatePresence>
          {showTimeline && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.5 }}
              className="mt-8"
            >
              <TiltCard className="bg-gray-800 rounded-lg shadow-lg p-8">
                <h2 className="text-2xl font-bold mb-6">My Journey</h2>
                <div className="relative">
                  {content.timelineEvents.map((event, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -50 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.2 }}
                      className="mb-8 flex"
                    >
                      <div className="flex-shrink-0 w-12 h-12 rounded-full bg-blue-600 flex items-center justify-center z-10">
                        <GitBranch className="text-white" size={20} />
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-semibold text-blue-400">
                          {event.date}
                        </div>
                        <div className="mt-1 text-lg font-semibold">
                          {event.title}
                        </div>
                        <div className="mt-1 text-gray-400">
                          {event.description}
                        </div>
                      </div>
                    </motion.div>
                  ))}
                  <div
                    className="absolute top-0 bottom-0 left-6 w-0.5 bg-gray-700 -z-10"
                    style={{ transform: "translateX(-50%)" }}
                  />
                </div>
              </TiltCard>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default About;
