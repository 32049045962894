import React from "react";
import content from "../content.json";
import { Download, Briefcase, GraduationCap, Award } from "lucide-react";
import { motion } from "framer-motion";
import TiltCard from "./TiltCard";

const MotionCard = motion.div;

const Cv = () => {
  return (
    <div className="text-white p-8">
      <h1 className="text-4xl font-bold mb-8 text-center">Curriculum Vitae</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <TiltCard className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Briefcase className="mr-2" /> Work Experience
          </h2>
          {content.experience.map((job, index) => (
            <div key={index} className="mb-4">
              <h3 className="text-xl font-medium">{job.position}</h3>
              <p className="text-gray-400">{job.company}</p>
              <p className="text-sm text-gray-500">{job.period}</p>
              <ul className="list-disc list-inside mt-2">
                {job.responsibilities.map((resp, idx) => (
                  <li key={idx} className="text-gray-300">
                    {resp}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </TiltCard>

        <TiltCard className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <GraduationCap className="mr-2" /> Education
          </h2>
          {content.education.map((edu, index) => (
            <div key={index} className="mb-4">
              <h3 className="text-xl font-medium">{edu.degree}</h3>
              <p className="text-gray-400">{edu.institution}</p>
              <p className="text-sm text-gray-500">{edu.year}</p>
            </div>
          ))}
        </TiltCard>

        <TiltCard className="bg-gray-800 p-6 rounded-lg shadow-lg md:col-span-2">
          <h2 className="text-2xl font-semibold mb-4">Skills</h2>
          <div className="flex flex-wrap gap-2">
            {content.skills.map((skill, index) => (
              <span
                key={index}
                className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm"
              >
                {skill}
              </span>
            ))}
          </div>
        </TiltCard>

        <TiltCard className="bg-gray-800 p-6 rounded-lg md:col-span-2">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Award className="mr-2" /> Certifications
          </h2>
          <ul className="list-disc list-inside">
            {content.certifications.map((cert, index) => (
              <li key={index} className="text-gray-300">
                {cert}
              </li>
            ))}
          </ul>
        </TiltCard>
      </div>

      <div className="mt-8 text-center">
        <TiltCard>
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-300">
            <Download className="mr-2" />
            <span>Download Full CV</span>
          </button>
        </TiltCard>
      </div>
    </div>
  );
};

export default Cv;
