import React, { useEffect, useState } from "react";
import { motion, useMotionValue, useSpring } from "framer-motion";

const CursorParticles = () => {
  const [particles, setParticles] = useState([]);
  const cursorX = useMotionValue(0);
  const cursorY = useMotionValue(0);

  const springConfig = { damping: 25, stiffness: 2000 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  const colors = [
    " bg-blue-100",
    " bg-blue-200",
    " bg-blue-300",
    " bg-blue-400",
    " bg-blue-500",
    " bg-blue-600",
    " bg-blue-700",
    " bg-blue-800",
    " bg-blue-900",
  ];

  useEffect(() => {
    const updateMousePosition = (e) => {
      cursorX.set(e.clientX);
      cursorY.set(e.clientY);
    };

    window.addEventListener("mousemove", updateMousePosition);

    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, [cursorX, cursorY]);

  useEffect(() => {
    const createParticle = () => {
      const newParticle = {
        id: Date.now(),
        x: cursorXSpring.get(),
        y: cursorYSpring.get(),
        opacity: 1,
      };

      setParticles((prevParticles) => [...prevParticles, newParticle]);

      setTimeout(() => {
        setParticles((prevParticles) =>
          prevParticles.filter((particle) => particle.id !== newParticle.id)
        );
      }, 1000);
    };

    const intervalId = setInterval(createParticle, 50);

    return () => {
      clearInterval(intervalId);
    };
  }, [cursorXSpring, cursorYSpring]);

  function getRandomColor() {
    let randomNum = Math.floor(Math.random() * 8);
    return colors[randomNum];
  }

  return (
    <div className="fixed inset-0 pointer-events-none z-50">
      {particles.map((particle) => (
        <motion.div
          key={particle.id}
          className={`absolute w-2 h-2 ${getRandomColor()} rounded-full`}
          style={{
            x: particle.x,
            y: particle.y,
          }}
          initial={{ opacity: 1, scale: 1 }}
          animate={{
            opacity: 0,
            scale: 0,
            x: particle.x + (Math.random() - 0.5) * 100,
            y: particle.y + (Math.random() - 0.5) * 100,
          }}
          transition={{ duration: 1, ease: "easeOut" }}
        />
      ))}
    </div>
  );
};

export default CursorParticles;
