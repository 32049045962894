import CursorParticles from "./components/CursorParticles";
import Portfolio from "./components/Portfolio";

function App() {
  return (
    <>
      <CursorParticles />
      <Portfolio />
    </>
  );
}

export default App;
